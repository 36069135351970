import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { DiaryResponse, NurseryAttendanceResponse, NurseryChildResponse } from "chaild-api/lib";
import ApiChildDiary from "@/api/ApiChildDiary";
import ApiAttendance from "@/api/ApiAttendance";
import LocalDataService from "@/service/LocalDataService";

export interface ChildDiaryState {
  diaries: DiaryResponse[];
  limit: number;
  page: number;
  totalItemsCount: number;

  attendances: NurseryAttendanceResponse[];
  child: NurseryChildResponse[]; // 園児

  // 園児 × 個人日誌
  TemporaryDiary: TemporaryDiary[];
}

interface TemporaryDiary {
  diary: DiaryResponse | null;
  child: NurseryChildResponse;
}

@Module({ dynamic: true, store, name: "child-diary", namespaced: true })
class ChildDiaryModule extends VuexModule implements ChildDiaryState {
  public diaries: DiaryResponse[] = [];

  public limit = 30;
  public page = 1;
  public totalItemsCount = 0;

  public attendances: NurseryAttendanceResponse[] = [];
  public child: NurseryChildResponse[] = [];

  public TemporaryDiary: TemporaryDiary[] = [];

  @Action
  public async search({
    classId,
    date,
    nurseryId,
  }: {
    classId?: number;
    date?: string;
    nurseryId: number;
  }) {
    const response = await ApiChildDiary.searchChildDiary({
      classId,
      date,
      //limit: this.limit,
      //skip: (this.page - 1) * this.limit,
      nurseryId,
    });
    this.setDiaries(response.diaries);
    // this.setTotalItemsCount(response.totalItemsCount);
    this.setTemporaryDiary();
  }

  @Mutation
  public setDiaries(diaries: DiaryResponse[]) {
    this.diaries = diaries;
  }

  @Action
  public async listAttendances({
    classId,
    date,
  }: {
    classId?: number;
    date: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiAttendance.searchAttendance({
        date,
        nurseryId,
        classId,
      });

      if (response) {
        const attendances = response.attendances as NurseryAttendanceResponse[];
        this.setAttendances(attendances);
      }
    }
  }

  @Mutation
  public setAttendances(attendances: NurseryAttendanceResponse[]) {
    this.attendances = attendances;
  }

  // 全ての園児を取得
  @Action
  public async AllChildList({
    classId,
    date,
  }: {
    classId?: number;
    date: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiAttendance.searchAttendance({
        date,
        nurseryId,
        classId,
        limit: this.limit,
        skip: (this.page - 1) * this.limit,
      });

      if (response) {
        const attendances = response.attendances as NurseryAttendanceResponse[];
        const child: NurseryChildResponse[] = [];
        attendances.forEach( (a) => {
          child.push(a.child)
        });
        this.setChild(child);
        this.setAttendances(attendances);
        this.setTotalItemsCount(response.totalItemsCount);
      }
    }
  }

  @Mutation
  public setChild(child: NurseryChildResponse[]) {
    this.child = child
  }

  @Mutation
  public setPage(value: number) {
    this.page = value;
  }

  @Mutation
  public setTotalItemsCount(value: number) {
    this.totalItemsCount = value;
  }

  // 園児と個人日誌を合わせる
  @Mutation
  public setTemporaryDiary() {
    const TemDiary: TemporaryDiary[] = [];
    let diary: DiaryResponse | null = null;
    this.child.map( async (c) => {
      this.diaries.map( async (d) => {
        if (c.childId == d.child.childId) {
          diary = d
        }
      })
      if (diary) {
        TemDiary.push({
          diary: diary,
          child: diary.child,
        })
      } else {
        TemDiary.push({
          diary: null,
          child: c,
        })
      }
      diary = null;
    })
    this.TemporaryDiary = TemDiary;
  }

  // 個人日誌の新規作成
  @Action
  public async createChildDiary(input: { childId: number; date: string; content: string }) {
    const response = await ApiChildDiary.createChildDiary(input);
    return response;
  }
}

export const childDiaryModule = getModule(ChildDiaryModule);
