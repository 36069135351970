import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiShift, { DayScheduleUpdateRequest } from '@/api/ApiShift'
import { ShiftPatternListResponse, ShiftPatternMonthlyResponse, ShiftPatternResponse, WorkDescriptionSettingResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';
import { ShiftTimetableCategoryType } from 'chaild-api/src/constant/shiftTimetableCategory';

export interface DailyScheduleState {
  yearMonthDate: string | null;
  dailySchedule: ShiftPatternListResponse | null;
}



@Module({ dynamic: true, store, name: 'daily-schedule', namespaced: true })
class DailyScheduleModule extends VuexModule implements DailyScheduleState {
  public yearMonthDate: string | null = null;
  public dailySchedule: ShiftPatternListResponse | null = null;

  @Action
  public async getDailySchedule() {
    const nurseryId = LocalDataService.getNurseryId()
    if (this.yearMonthDate && nurseryId) {
      const date = dayjs(this.yearMonthDate)
      const year = date.year()
      const month = date.month() + 1
      const day = date.date()

      const response = await ApiShift.getDailySchedule({
        nurseryId,
        year,
        month,
        day
      })

      if (response) {
        this.setDailySchedule(response)
      }
    }
  }

  /**
   * PDF出力
   */
  @Action({ rawError: true })
  public async dlShiftPattern() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const date = dayjs(this.yearMonthDate)
      const year = date.year()
      const month = date.month() + 1
      const day = date.date()
      const response = await ApiShift.dlDailyShiftPattern({
        nurseryId,
        year,
        month,
        day
      }).catch();
      if (response)  {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async dlMonthlyShiftPattern() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const date = dayjs(this.yearMonthDate)
      const year = date.year() + 500
      const month = date.month() + 1
      const day = date.date()
      const response = await ApiShift.dlDailyShiftPattern({
        nurseryId,
        year,
        month,
        day
      }).catch();
      if (response)  {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async UpdateExpectedPlacement() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const date = this.yearMonthDate
      const response = await ApiShift.UpdateExpectedPlacement({
        nurseryId,
        date
      }).catch();
      if (response)  {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async UpdateMonthlyExpectedPlacement(date: string) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      console.log(`日付：${date}`)
      //const date = this.yearMonthDate
      const response = await ApiShift.UpdateManthlyExpectedPlacement({
        nurseryId,
        date
      }).catch();
      if (response)  {
        return response;
      }
    }
  }

  @Mutation
  public setYearMonthDate(date: string | null) {
    this.yearMonthDate = date
  }

  @Mutation
  public setDailySchedule(schedule: ShiftPatternListResponse | null) {
    this.dailySchedule = schedule
  }
}

export const dailyScheduleModule = getModule(DailyScheduleModule);