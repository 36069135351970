
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";

import {
  DiaryResponse,
  NurseryAttendanceResponse,
  NurseryChildResponse,
  NurseryClassResponse,
} from "chaild-api/lib";
import { classModule } from "@/store/dataModules/classModule";
import { childDiaryModule } from "@/store/dataModules/childDiaryModule";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import { statusItems } from "@/constants/attendance";

interface DataType {
  classFilterValue: number | string | null;
  dateFilterValue: string | null;
  preDateFilterValue: string;
  isFetching: boolean;
  page: number;
}

export default Vue.extend({
  name: "ChildReport",

  components: {
    PageTitle,
  },

  watch: {
    page: {
      handler(val) {
        childDiaryModule.setPage(val);
        this.search();
      },
    },
    classFilterValue: {
      handler(val) {
        if (!val) {
          this.classFilterValue = "all"
        }
      }
    }
  },

  computed: {
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    // diaries(): DiaryResponse[] {
    //   return childDiaryModule.diaries;
    // },
    diaries() {
      return childDiaryModule.TemporaryDiary;
    },
    attendances(): NurseryAttendanceResponse[] {
      return childDiaryModule.attendances;
    },
    isSearchValid(): boolean {
      if (this.classFilterValue && this.dateFilterValue) {
        return true;
      } else if (this.classFilterValue == "all" && this.dateFilterValue) {
        return true;
      }
      return false;
    },
    limit(): number {
      return childDiaryModule.limit;
    },
    totalItemsCount(): number {
      return childDiaryModule.totalItemsCount;
    },
  },

  data: (): DataType => {
    return {
      classFilterValue: "all",
      dateFilterValue: dayjs().format("YYYY-MM-DD"),
      preDateFilterValue: dayjs().format("YYYY-MM-DD"),
      isFetching: false,
      page: 1,
    };
  },

  methods: {
    async search() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.isFetching = true;

        // 全ての園児を取得
        if (this.dateFilterValue) {
          if (this.classFilterValue == "all") {
            await childDiaryModule.AllChildList({
              date: this.dateFilterValue,
            })
          } else {
            await childDiaryModule.AllChildList({
              classId: this.classFilterValue as number,
              date: this.dateFilterValue,
            })
          }
          this.preDateFilterValue = this.dateFilterValue;
        }

        await childDiaryModule.search({
          classId:
            this.classFilterValue && this.classFilterValue !== "all"
              ? (this.classFilterValue as number)
              : undefined,
          date: this.dateFilterValue || undefined,
          nurseryId: nurseryId,
        });

        // if (
        //   this.classFilterValue &&
        //   this.dateFilterValue &&
        //   this.classFilterValue !== "all"
        // ) {
        //   await childDiaryModule.listAttendances({
        //     classId: this.classFilterValue as number,
        //     date: this.dateFilterValue,
        //   });
        // }

        this.isFetching = false;
      }
    },
    // 検索ボタン押下時にページ１にする
    async searchBtn() {
      await this.search();
      this.page = 1;
    },
    getClassName(diary: DiaryResponse) {
      if (diary.child && diary.child.nurseryClasses) {
        const date = dayjs(diary.date);
        let year = date.year();
        if (Number(date.format("M")) < 4) {
          year = dayjs(date).subtract(1, "year").year();
        }
        const theClass = diary.child.nurseryClasses.find(
          (c) => String(c.year) == String(year)
        );
        if (theClass) {
          return theClass.className;
        }
      }
      return "不明なクラス";
    },
    getAttendance(diary: DiaryResponse) {
      const find = this.attendances.find(
        (a) => a.child.childId === diary.child.childId && a.date === diary.date
      );
      if (find && find.status) {
        return statusItems.find((a) => a.value == find.status)?.text;
      }
      return "記録なし";
    },
    // クラス名取得
    getChildClassName(child: NurseryChildResponse) {
      if (child && child.nurseryClasses) {
        const date = dayjs(this.dateFilterValue);
        let year = date.year();
        if (Number(date.format("M")) < 4) {
          year = dayjs(date).subtract(1, "year").year();
        }
        const theClass = child.nurseryClasses.find(
          (c) => String(c.year) == String(year)
        );
        if (theClass) {
          return theClass.className;
        }
      }
      return "不明なクラス";
    },
    // 登園状況取得
    getChildAttendance(child: NurseryChildResponse) {
      const find = this.attendances.find(
        (a) => a.child.childId === child.childId
      );
      if (find && find.status) {
        return statusItems.find((a) => a.value == find.status)?.text;
      }
      return "記録なし";
    },
    // 個人日誌新規作成
    async newDiary(childId: number, date: string) {
      this.isFetching = true;
      const content = "特記事項なし";
      console.log(date)
      await childDiaryModule.createChildDiary({
        childId: childId,
        date: date,
        content: content,
      })
      this.isFetching = false;
      this.$router.push({path: `/dailyreport/child/${childId}/${date}`});
    }
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      classModule.listClass({
        nurseryId: nurseryId,
      });
      await this.search();
    }
  },
});
