
import Vue from "vue";

import { childDiaryDetailModule } from "@/store/dataModules/childDiaryDetailModule";
import dayjs from "dayjs";
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { attendanceEditModule } from "@/store/dataModules/attendanceEditModule";
import { 
  DefecationStatus, 
  DefecationStatusType, 
  MealStatus, 
  MealStatusType, 
  NurseryAttendanceResponse, 
  NurseryDefecationResponse, 
  NurseryMilkResponse, 
  NurseryTemperatureResponse, 
} from "chaild-api/lib";

interface DataType {
  date: string | null;
  childId: number | null;
  loading: boolean;
  addRecordDialog: boolean; // 記録追加モーダル元
  editRecordDialog: boolean; // 記録編集モーダル元
  isRecordetAtPicker: boolean; // モーダル表示
  editRecordAtPicker: boolean;
  otherRecord: string; // 表示項目
  defecation: DefecationStatusType; // 排便
  defecationRecord: NurseryDefecationResponse | null;
  choices: any; // 排便状態
  temperature: number; // 体温
  temperatureRecord: NurseryTemperatureResponse | null;
  amount: any; // ミルク量
  milkCategory: string; // ミルク種類
  milkRecord: NurseryMilkResponse | null;
  recordedAt: string; // 時間(HH:mm)
  editRecordedAt: string; // 時間(yyyy-mm-ddT00:00:00.000Z)
  content: string | null; // 活動記録/発達情報
  statusList: any; // 喫食状態
  showMenuItem: MenuRecord; // 喫食項目
  mealRecord: MealRecord; // 喫食内容
}

// 喫食項目
interface MenuRecord {
  stapleFood: boolean;
  mainDish: boolean;
  sideDish: boolean;
  soup: boolean;
  dessert: boolean;
  milk: boolean;
  snack: boolean;
}

// 喫食内容
interface MealRecord {
  stapleFood: MealStatusType | undefined;
  mainDish: MealStatusType | undefined;
  sideDish: MealStatusType | undefined;
  soup: MealStatusType | undefined;
  dessert: MealStatusType | undefined;
  milk: MealStatusType | undefined;
  snack: MealStatusType | undefined;
}

export default Vue.extend({
  data: (): DataType => {
    return {
      date: null,
      childId: null,
      loading: false,
      addRecordDialog: false,
      editRecordDialog: false,
      isRecordetAtPicker: false,
      editRecordAtPicker: false,
      otherRecord: "",
      defecation: DefecationStatus.normal,
      defecationRecord: null,
      choices: [
        {
          text: '硬い',
          value: DefecationStatus.hard,
        },
        {
          text: '普通',
          value: DefecationStatus.normal,
        },
        {
          text: '軟らかい',
          value: DefecationStatus.soft,
        },
      ],
      temperature: 365 / 10,
      temperatureRecord: null,
      amount: null,
      milkCategory: 'breast',
      milkRecord: null,
      recordedAt: "",
      editRecordedAt: "",
      content: '',
      statusList: [
        { status: MealStatus.refill, title: 'おかわり' },
        { status: MealStatus.finished, title: '完食' },
        { status: MealStatus.unfinished, title: '少し残した' },
        { status: MealStatus.half, title: '半分食べた' },
        { status: MealStatus.little, title: '少し食べた' },
        { status: MealStatus.notEat, title: '食べなかった' },
        { status: MealStatus.notServed, title: '未提供' },
      ],
      mealRecord: {
        stapleFood: undefined,
        mainDish: undefined,
        sideDish: undefined,
        soup: undefined,
        dessert: undefined,
        milk: undefined,
        snack: undefined,
      },
      showMenuItem: {
        stapleFood: false,
        mainDish: false,
        sideDish: false,
        soup: false,
        dessert: false,
        milk: false,
        snack: false,
      },
    };
  },

  watch: {
    addRecordDialog: {
      handler(val) {
        // 追加モーダルが閉じられたとき全てリセット
        if (!val) {
          this.isRecordetAtPicker = false;
          this.recordedAt = "";
          this.editRecordedAt = "";
          this.temperature = 365 / 10;
          this.defecation = DefecationStatus.normal;
          this.amount = null;
          this.milkCategory = "breast";
          this.otherRecord = '';
        }
      },
    },
    editRecordDialog: {
      handler(val) {
        // 編集モーダルが閉じられたとき全てリセット
        if (!val) {
          this.editRecordAtPicker = false;
          this.recordedAt = "";
          this.editRecordedAt = "";
          this.temperature = 365 / 10;
          this.defecation = DefecationStatus.normal;
          this.amount = null;
          this.milkCategory = "breast";
          this.otherRecord = '';
          this.defecationRecord = null;
          this.temperatureRecord = null;
          this.milkRecord = null;
        }
      },
    },
  },

  computed: {
    report() {
      return childDiaryDetailModule.report;
    },
    diary() {
      return childDiaryDetailModule.diary;
    },
    attendance(): NurseryAttendanceResponse | null {
      return attendanceEditModule.attendance;
    },
    // 体温選択
    optionsTemperature() {
      const options: Array<{ text: string; value: number }> = [];
      for (let i = 350; i < 400; i++) {
        options.push({
          text: `${(i / 10).toFixed(1)}℃`,
          value: Number((i / 10).toFixed(1)),
        });
      }
      return options;
    },
    // ミルク量選択
    milkChoice() {
      const Choice: Array<{ id: number | null; value: number | string }> = [];
      let i = 0;
      for (let v = 0; v < 310; v += 10) {
        Choice.push({
          id: i,
          value: Number(v),
        })
        i++;
      }
      Choice.unshift({
        id: null,
        value: '不明',
      });
      return Choice;
    },
    // ミルク種類選択
    optionsMilk() {
      const options = [
        { value: 'breast', text: '母乳' },
        { value: 'formula', text: 'ミルク' },
      ];
      return options;
    },
    // 保存ボタンのアクティブ
    isSaveContent(): boolean {
      if (!this.content) {
        return false
      } else if (this.content != this.diary?.content) {
        return true;
      }
      return false;
    },
    // 保存ボタンのアクティブ
    isSaveOtherRecord(): boolean {
      if (this.recordedAt && this.recordedAt != null && this.defecation) {
        return true;
      } else if (this.recordedAt && this.recordedAt != null && this.temperature) {
        return true;
      } else if (this.recordedAt && this.recordedAt != null && this.amount) {
        return true;
      }
      return false;
    },
  },

  methods: {
    formatMealStatus(status: string) {
      if (status === "finished") {
        return "完食";
      } else if (status === "refill") {
        return "おかわり";
      } else if (status === "unfinished") {
        return "少し残した";
      } else if (status === "half") {
        return "半分食べた";
      } else if (status === "little") {
        return "少し食べた";
      } else if (status === "notEat") {
        return "食べなかった";
      } else if (status === "notServed") {
        return "未提供";
      }
    },
    formatDefecation(defecation: string) {
      if (defecation === "hard") {
        return "かたい";
      } else if (defecation === "normal") {
        return "ふつう";
      } else if (defecation === "soft") {
        return "やわらかい";
      }
    },
    async getAttendance(input: { childId: string; date: string }) {
      attendanceEditModule.setChildId(Number(input.childId));
      attendanceEditModule.setDate(input.date);
      await attendanceEditModule.getAttendance();
    },
    formatDate(date: string | null | undefined) {
      if (date) {
        return dayjs(date).format("HH:mm");
      }
      return null;
    },
    formatTemperature(value: number): string {
      return value.toFixed(1);
    },
    // 入力事項
    rules: (value: any) => !!value || '必須入力項目です',
    // ステップ
    allowedStep: (m: any) => m % 5 === 0,
    // 時間フォーマット
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
    // 時間
    changeRecordedAt(recordedAt: string) {
      const recordedAtHour = recordedAt.split(':')[0];
      const recordedAtMinutes = recordedAt.split(':')[1];
      const recordedAtTime = dayjs()
        .hour(Number(recordedAtHour))
        .minute(Number(recordedAtMinutes));
      this.recordedAt = dayjs(recordedAtTime).toISOString();
      this.editRecordedAt = this.recordedAt;
    },
    // 追加モーダル別表示
    changeAddOtherRecord(value: string) {
      this.otherRecord = value;
      this.addRecordDialog = true;
    },
    // 排便記録編集モーダル
    changeEditDefecation(defecation: NurseryDefecationResponse) {
      this.otherRecord = 'defecation';
      this.defecationRecord = defecation;
      this.editRecordDialog = true;
    },
    // 体温記録編集モーダル
    changeEditTemperature(temperature: NurseryTemperatureResponse) {
      this.otherRecord = 'temperature';
      this.temperatureRecord = temperature;
      this.editRecordDialog = true;
    },
    // ミルク記録編集モーダル
    changeEditMilk(milk: NurseryMilkResponse) {
      this.otherRecord = 'milk';
      this.milkRecord = milk;
      this.editRecordDialog = true;
    },
    // 排便
    changeDefecationState(defecation: DefecationStatusType) {
      this.defecation = defecation;
    },
    // 体温
    changeTemperature(temperature: number) {
      this.temperature = temperature;
    },
    // ミルク量
    changeAmount(amount: number) {
      this.amount = amount;
    },
    // ミルク種類
    changeMilkCategory(milkCategory: string) {
      this.milkCategory = milkCategory;
    },
    // 喫食情報取得
    getMeal() {
      if (this.report?.nursery.meal) {
        this.mealRecord.stapleFood = this.report.nursery.meal.stapleFood;
        this.mealRecord.mainDish = this.report.nursery.meal.mainDish;
        this.mealRecord.sideDish = this.report.nursery.meal.sideDish;
        this.mealRecord.soup = this.report.nursery.meal.soup;
        this.mealRecord.dessert = this.report.nursery.meal.dessert;
        this.mealRecord.milk = this.report.nursery.meal.milk;
        this.mealRecord.snack = this.report.nursery.meal.snack;
      }
    },
    // 喫食記録追加
    async createMealReport() {
      this.loading = true
      if (this.childId && this.date) {
        await childDiaryDetailModule.createMeal({
          childId: this.childId,
          date: this.date,
          stapleFood: 'notServed',
          mainDish: 'notServed',
          sideDish: 'notServed',
          soup: 'notServed',
          dessert: 'notServed',
          milk: 'notServed',
          snack: 'notServed',
          comment: '',
        })
      }
      await childDiaryDetailModule.getDailyReport();
      await childDiaryDetailModule.getDiary();
      this.getMeal()
      this.loading = false
    },
    // 喫食状態を全て完食に変更
    fullEat() {
      this.mealRecord.stapleFood = 'finished';
      this.mealRecord.mainDish = 'finished';
      this.mealRecord.sideDish = 'finished';
      this.mealRecord.soup = 'finished';
      this.mealRecord.dessert = 'finished';
      this.mealRecord.milk = 'finished';
      // this.mealRecord.snack = 'finished';
      this.saveMeal()
    },
    // 喫食選択
    showMenu(value: string) {
      switch (value) {
        case 'stapleFood':
          this.showMenuItem.stapleFood = true;
          return
        case 'mainDish':
          this.showMenuItem.mainDish = true;
          return
        case 'sideDish':
          this.showMenuItem.sideDish = true;
          return
        case 'soup':
          this.showMenuItem.soup = true;
          return
        case 'dessert':
          this.showMenuItem.dessert = true;
          return
        case 'milk':
          this.showMenuItem.milk = true;
          return
        case 'snack':
          this.showMenuItem.snack = true;
          return
        default:
          return
      }
    },
    // 喫食内容変更
    changeMealStatus(value: string, status: MealStatusType) {
      switch (value) {
        case 'stapleFood':
          if (this.mealRecord.stapleFood == status) {
            return
          }
          this.mealRecord.stapleFood = status;
          break
        case 'mainDish':
          if (this.mealRecord.mainDish == status) {
            return
          }
          this.mealRecord.mainDish = status;
          break
        case 'sideDish':
          if (this.mealRecord.sideDish == status) {
            return
          }
          this.mealRecord.sideDish = status;
          break
        case 'soup':
          if (this.mealRecord.soup == status) {
            return
          }
          this.mealRecord.soup = status;
          break
        case 'dessert':
          if (this.mealRecord.dessert == status) {
            return
          }
          this.mealRecord.dessert = status;
          break
        case 'milk':
          if (this.mealRecord.milk == status) {
            return
          }
          this.mealRecord.milk = status;
          break
        case 'snack':
          if (this.mealRecord.snack == status) {
            return
          }
          this.mealRecord.snack = status;
          break
        default:
          break
      }
      this.saveMeal()
    },
    // 喫食情報保存
    async saveMeal() {
      this.loading = true;
      if (this.childId && this.report?.nursery.meal) {
        await childDiaryDetailModule.updateMeal({
          childId: this.childId,
          mealId: this.report.nursery.meal.mealId,
          stapleFood: this.mealRecord.stapleFood,
          mainDish: this.mealRecord.mainDish,
          sideDish: this.mealRecord.sideDish,
          soup: this.mealRecord.soup,
          dessert: this.mealRecord.dessert,
          milk: this.mealRecord.milk,
          snack: this.mealRecord.snack,
        })
      } 
      await childDiaryDetailModule.getDailyReport();
      await childDiaryDetailModule.getDiary();
      this.getMeal()
      this.loading = false;
      return
    },
    // 保存
    async save(value: string) {
      this.loading = true;
      if (this.childId && this.date) {
        switch (value) {
          case 'defecation':
            await childDiaryDetailModule.saveDefecation({
            childId: this.childId,
            date: this.date,
            recordedAt: this.editRecordedAt,
            state: this.defecation
            })
            break
          case 'temperature':
            await childDiaryDetailModule.saveTemperature({
              childId: this.childId,
              date: this.date,
              recordedAt: this.editRecordedAt,
              temperature: this.temperature,
            })
            break
          case 'milk':
            await childDiaryDetailModule.saveMilk({
              childId: this.childId,
              date: this.date,
              recordedAt: this.editRecordedAt,
              amountId: this.amount,
              milkCategory: this.milkCategory,
            })
            break
          default:
            break
        }
      } else {
        this.loading = false;
        return alert('記録の保存に失敗しました')
      }
      await childDiaryDetailModule.getDailyReport();
      await childDiaryDetailModule.getDiary();
      this.loading = false;
      this.addRecordDialog = false;
    },
    // 変更
    async change(value: string) {
      this.loading = true;
      if (this.childId) {
        switch (value) {
          case 'defecation':
            if (this.defecationRecord) {
              await childDiaryDetailModule.updateDefecation({
              childId: this.childId,
              defecationId: this.defecationRecord?.defecationId,
              recordedAt: this.editRecordedAt,
              state: this.defecation
              })
            }
            break
          case 'temperature':
            if (this.temperatureRecord) {
              await childDiaryDetailModule.updateTemperature({
                childId: this.childId,
                temperatureId: this.temperatureRecord.temperatureId,
                recordedAt: this.editRecordedAt,
                temperature: this.temperature,
              })
            }
            break
          case 'milk':
            if (this.milkRecord) {
              await childDiaryDetailModule.updateMilk({
                childId: this.childId,
                milkId: this.milkRecord.milkId,
                recordedAt: this.editRecordedAt,
                amountId: this.amount,
                milkCategory: this.milkCategory,
              })
            }
            break
          default:
            break
        }
      } else {
        this.loading = false;
        return alert('記録の変更に失敗しました')
      }
      await childDiaryDetailModule.getDailyReport();
      await childDiaryDetailModule.getDiary();
      this.editRecordDialog = false;
      this.loading = false;
    },
    // 削除
    async remove(value: string) {
      this.loading = true;
      if (this.childId) {
        switch (value) {
          case 'defecation':
            if (this.defecationRecord) {
              await childDiaryDetailModule.deleteDefecation({
                childId: this.childId,
                defecationId: this.defecationRecord.defecationId
              })
            }
            break
          case 'temperature':
            if (this.temperatureRecord) {
              await childDiaryDetailModule.deleteTemperature({
                childId: this.childId,
                temperatureId: this.temperatureRecord.temperatureId
              })
            }
            break
          case 'milk':
            if (this.milkRecord) {
              await childDiaryDetailModule.deleteMilk({
                childId: this.childId,
                milkId: this.milkRecord.milkId
              })
            }
            break
          default:
            break
        }
      } else {
        this.loading = false;
        return alert('記録の削除に失敗しました')
      }
      await childDiaryDetailModule.getDailyReport();
      await childDiaryDetailModule.getDiary();
      this.loading = false;
      this.editRecordDialog = false;
    },
    // 活動記録/発達状況の保存
    async saveContent() {
      if (this.content) {
        this.loading = true;
        if (this.diary && this.childId){
          await childDiaryDetailModule.updateContent({
            childId: this.childId,
            diaryId: this.diary.diaryId,
            content: this.content,
          })
        }
      } else {
        // ふつうは通ることがない
        this.loading = false
        return alert('記載がありません')
      }
      await childDiaryDetailModule.getDailyReport();
      await childDiaryDetailModule.getDiary();
      if (this.diary) {
        this.content = this.diary.content
      }
      this.loading = false
      return this.content
    },
  },
  async mounted() {
    this.loading = true
    const childId = this.$route.params.childId as string;
    const date = this.$route.params.date as string;

    if (childId && date) {
      try {
        const parsed = parseInt(childId);
        childDiaryDetailModule.setChildId(parsed);
        childDiaryDetailModule.setDate(date);

        await childDiaryDetailModule.getDailyReport();
        await childDiaryDetailModule.getDiary();

        await this.getAttendance({
          childId: childId,
          date: date,
        });

        this.date = date;
        this.childId = parsed;
      } catch {
        this.loading = false
        return;
      }
    }
    if (this.diary) {
      this.content = this.diary.content
      }
    this.getMeal()
    this.loading = false
  },
});
