
import Vue from "vue";
import draggable from "vuedraggable";

import { monthlyScheduleModule } from "@/store/viewModules/work-schedule/monthlyScheduleModule";
import ApiShift, {
  DayScheduleUpdateRequest,
  DayScheduleUpdateRequestTimetable,
} from "@/api/ApiShift";
import {
  shiftPatternModule,
  WorkDescriptions,
} from "@/store/viewModules/work-schedule/shiftPatternModule";
import ValidationMixin from "@/mixins/ValidationMixin";

import MonthPager from "@/components/atoms/MonthPager.vue";
import MonthDatePicker from "@/components/organisms/work-schedule/MonthDatePicker.vue";
import ScrollablePanels from "@/components/molecules/ScrollablePanels.vue";
import ColorPicker from "@/components/molecules/ColorPicker.vue";
import HourMinuteInput from "@/components/molecules/HourMinuteInput.vue";
import dayjs from "dayjs";
import { NurseryUserResponse } from "chaild-api/src/component/nursery/user/types";
import { workDescriptionModule } from "@/store/viewModules/work-schedule/workDescriptionModule";
import "dayjs/locale/ja";
import LocalDataService from "@/service/LocalDataService";
import { dailyScheduleModule } from "@/store/viewModules/work-schedule/dailyShceduleModule";
import { colors } from "vuetify/lib";

const hours = [...Array(12)].map((emp, i) => i);
const minutes = [...Array(4)].map((emp, i) => i * 15);
const descriptionsMap = Object.keys(WorkDescriptions).map((key) => {
  return {
    text: WorkDescriptions[key],
    value: key,
  };
});

interface DataType {
  isFetching: boolean;
  isUpdatingStaffs: boolean;
  isRegisterDialogOpen: boolean;

  isCopyingMembersFromLastMonth: boolean;

  applyingUser: NurseryUserResponse | null;
  applyingDates: number[];
  applyingPatternId: number | null;
  isApplying: boolean;

  isFetchingPattern: boolean;
  isUpdatingShift: boolean;
  editingUser: NurseryUserResponse | null;
  editingDate: string | null;

  addingUser: NurseryUserResponse | null;
  addingDate: string | null;
  addingPatternId: number | null;
  isUpdatePlacement: boolean;
  tableDates: Array<{ 
    date: string; 
    summaryMax?: string; 
    summaryMin?: string; 
  }>;
}

interface StaffRegistrationObject {
  userId: number;
  firstName: string;
  lastName: string;
  isRegistered: boolean;
}

interface ScheduleTableRowItem {
  name: string;
  attendanceCount: number;
  offCount: number;
  dailyPatterns: {
    date: string;
    abbreviation: string | null;
    color: string | null;
  }[];
  user: NurseryUserResponse;
  totalShiftWorkingDays: number; // 日数：シフトで作業内容または配慮が必要な園児の保育が入っている日数
  totalShiftHours: number; // 時間：作業内容（または配慮が必要な園児の保育）（保育対象外の時間を含む） - 「休憩」の時間
  totalShiftPaidHolidays: number; // 有休：シフト項目で有給としてとっている日の日数。ただし、1日に複数設定ある場合は、0.5としてカウントする。
}

export default Vue.extend({
  components: {
    draggable,
    MonthPager,
    MonthDatePicker,
    ScrollablePanels,
    ColorPicker,
    HourMinuteInput,
  },
  mixins: [ValidationMixin],
  data: (): DataType => {
    return {
      isFetching: false,
      isUpdatingStaffs: false,
      isRegisterDialogOpen: false,

      isCopyingMembersFromLastMonth: false,

      applyingUser: null,
      applyingDates: [],
      applyingPatternId: null,
      isApplying: false,

      isFetchingPattern: false,
      isUpdatingShift: false,
      editingUser: null,
      editingDate: null,

      addingUser: null,
      addingDate: null,
      addingPatternId: null,
      isUpdatePlacement: false,
      tableDates: [],
    };
  },
  computed: {
    yearMonth: {
      get(): string {
        return monthlyScheduleModule.yearMonth;
      },
      async set(value: string) {
        await Promise.all([
          this.onYearMonthChange(value)
        ]);
        await Promise.all([
          this.updateTableDates()
        ]);
      },
    },
    monthlySchedules() {
      if (monthlyScheduleModule.monthlySchedule) {
        return monthlyScheduleModule.monthlySchedule.items;
      }
      return [];
    },
    subsidyChildcare() {
      if (monthlyScheduleModule.monthlySchedule) {
        return monthlyScheduleModule.monthlySchedule.subsidyChildcare;
      }
      return undefined;
    },
    summaries() {
      if (monthlyScheduleModule.monthlySchedule) {
        return monthlyScheduleModule.monthlySchedule.summaries;
      }
      return undefined;
    },
    scheduleTableRows(): ScheduleTableRowItem[] {
      return this.monthlySchedules.map((schedule) => {
        let name = `${schedule.nurseryUser.lastName} ${schedule.nurseryUser.firstName}`;
        if (name.length > 10) {
          name = name.slice(0, 10) + "...";
        }

        // const attendanceCount = schedule.dailyPatterns.filter((p) => p.)

        for (const pattern of schedule.dailyPatterns) {
            if (pattern.color) {
                // 白色とのコントラスト比を計算
                const contrastRatio = this.calculateContrastRatio(pattern.color, "#FFFFFF");
                const fontColor = contrastRatio < 3.0 ? "#000000" : "#FFFFFF";

                console.log("コントラスト比：", contrastRatio);
                console.log("文字色：", fontColor);

                pattern.color = pattern.color + "&" + fontColor
            }
        }

        return {
          name,
          attendanceCount: 0,
          offCount: 0,
          dailyPatterns: schedule.dailyPatterns,
          user: schedule.nurseryUser,
          totalShiftWorkingDays: schedule.totalShiftWorkingDays,
          totalShiftHours: schedule.totalShiftHours,
          totalShiftPaidHolidays: schedule.totalShiftPaidHolidays,
        };
      });
    },
    numberOfDates(): number {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth();
      return daysInMonth;
    },
    /*
    tableDates(): Array<{
      date: string;
      summaryMax?: string;
      summaryMin?: string;
    }> {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth();
      const startOfMonth = dayjs(this.yearMonth).startOf("month");
      const dates = [...Array(daysInMonth)].map((emp, i) => {
        const date = dayjs(startOfMonth)
          .add(i, "day")
          .locale("ja")
          .format("M/D (ddd)");
        const dateToFindSummary = dayjs(startOfMonth)
          .add(i, "day")
          .format("YYYY-MM-DD");

        const summary = this.summaries?.find(
          (s) => s.date === dateToFindSummary
        ) as {
          date: string;
          overall: {
            maxGovExpected: number | null;
            minGovExpected: number | null;
          };
        };

        return {
          date,
          summaryMax:
            summary && summary.overall.maxGovExpected
              ? String(summary.overall.maxGovExpected)
              : "-",
          summaryMin:
            summary && summary.overall.minGovExpected
              ? String(summary.overall.minGovExpected)
              : "-",
        };
      });

      return dates;
    },
    */
    staffOptions() {
      return monthlyScheduleModule.shiftStaffs.map((staff) => {
        return {
          value: staff.userId,
          text: `${staff.lastName} ${staff.firstName}`,
        };
      });
    },
    staffObjects: {
      get(): StaffRegistrationObject[] {
        return monthlyScheduleModule.shiftStaffs.map((staff) => {
          const isGonnaRegister =
            monthlyScheduleModule.registerStaffIds.indexOf(staff.userId) >= 0;
          const findSchedule = this.monthlySchedules.find(
            (item) => item.nurseryUser.userId === staff.userId
          );
          const hasAlreadyRegistered = findSchedule ? true : false;

          const isGonnaRemove =
            monthlyScheduleModule.removeStaffIds.indexOf(staff.userId) >= 0;

          const isRegistered = isGonnaRemove
            ? false
            : isGonnaRegister || hasAlreadyRegistered;
          return {
            userId: staff.userId,
            firstName: staff.firstName,
            lastName: staff.lastName,
            isRegistered,
          };
        });
      },
      async set(value: StaffRegistrationObject[]) {
        const shiftUserIds = value.map((item) => item.userId);
        monthlyScheduleModule.setSortStaffIds(shiftUserIds);

        this.isUpdatingStaffs = true;
        await monthlyScheduleModule.sortShiftUsers();
        this.isUpdatingStaffs = false;

        this.isFetching = true;
        await monthlyScheduleModule.listMonthlySchedule();
        this.isFetching = false;
      },
    },
    shiftPatternOptions() {
      return shiftPatternModule.shiftPatterns.map((pattern) => ({
        value: pattern.shiftPatternId,
        text: pattern.name,
      }));
    },
    registerUserIds() {
      return monthlyScheduleModule.registerStaffIds;
    },
    removeUserIds() {
      return monthlyScheduleModule.removeStaffIds;
    },
    isApplyButtonValid() {
      if (this.applyingPatternId) {
        return true;
      }
      return false;
    },
    isPatternModalOpen: {
      get() {
        return !!this.applyingUser;
      },
      set(value: boolean) {
        if (!value) {
          this.applyingUser = null;
          this.applyingPatternId = null;
          this.applyingDates = [];
        }
      },
    },
    editingPattern(): DayScheduleUpdateRequest | null {
      return monthlyScheduleModule.editingShiftPattern;
    },
    isEditModalOpen: {
      get() {
        if (this.editingPattern) {
          return true;
        }
        return false;
      },
      set(value: boolean) {
        if (!value) {
          this.editingUser = null;
          monthlyScheduleModule.setShiftPattern(null);
        }
      },
    },
    isAddScheduleModalOpen: {
      get() {
        if (this.addingUser && this.addingDate) {
          return true;
        }
        return false;
      },
      set(value: boolean) {
        if (!value) {
          this.addingUser = null;
          this.addingDate = null;
        }
      },
    },
    workDescriptions() {
      return workDescriptionModule.workDescriptions;
    },
    workDescriptionOptions(): Record<string, any>[] {
      return [
        ...this.workDescriptions.map((wd) => ({
          text: wd.name,
          value: wd.workDescriptionId,
        })),
      ];
    },
    descriptions(): Record<string, any>[] {
      return [...descriptionsMap];
    },
  },
  methods: {
    async onYearMonthChange(value: string) {
      this.isFetching = true;
      await monthlyScheduleModule.onYearMonthChange(value);
      this.isFetching = false;
    },
    addRegisterUserId(value: boolean, userId: number) {
      if (value) {
        const findSchedule = this.monthlySchedules.find(
          (item) => item.nurseryUser.userId === userId
        );
        if (findSchedule) {
          monthlyScheduleModule.removeRemoveStaffId(userId);
        } else {
          monthlyScheduleModule.addRegisterStaffIds([userId]);
        }
      } else {
        const findSchedule = this.monthlySchedules.find(
          (item) => item.nurseryUser.userId === userId
        );
        if (findSchedule) {
          monthlyScheduleModule.addRemoveStaffIds([userId]);
        } else {
          monthlyScheduleModule.removeRegisterStaffId(userId);
        }
      }
    },
    selectAllStaffs(value: boolean) {
      if (value) {
        const unregistereds = this.staffObjects
          .filter((so) => {
            const findSchedule = this.monthlySchedules.find(
              (item) => item.nurseryUser.userId === so.userId
            );
            if (findSchedule) {
              return false;
            }
            return true;
          })
          .map((so) => so.userId);

        const gonnaRemoves = [...this.removeUserIds];

        const targets = [...unregistereds, ...gonnaRemoves];

        targets.forEach((id) => {
          this.addRegisterUserId(true, id);
        });
      } else {
        const alreadyRegistereds = this.staffObjects
          .filter((so) => {
            const findSchedule = this.monthlySchedules.find(
              (item) => item.nurseryUser.userId === so.userId
            );
            if (findSchedule) {
              return true;
            }
            return false;
          })
          .map((so) => so.userId);

        const gonnaRegisters = [...this.registerUserIds];
        const registereds = [...alreadyRegistereds, ...gonnaRegisters];
        registereds.forEach((id) => {
          this.addRegisterUserId(false, id);
        });
      }
    },
    openPatternModal(user: NurseryUserResponse) {
      this.applyingUser = user;
    },
    async updateStaffIds() {
      this.isUpdatingStaffs = true;
      await monthlyScheduleModule.registerStaffs();
      await monthlyScheduleModule.removeStaffs();
      this.isUpdatingStaffs = false;
      this.isRegisterDialogOpen = false;
    },
    async applyPattern() {
      if (this.applyingPatternId && this.applyingUser) {
        this.isApplying = true;
        await monthlyScheduleModule.applyPattern({
          shiftPatternId: this.applyingPatternId,
          userId: this.applyingUser.userId,
          days: this.applyingDates,
        });
        this.isApplying = false;
        // this.applyingUser = null;
        this.applyingPatternId = null;
        this.applyingDates = [];
      }
    },
    async openDayDetail(user: NurseryUserResponse, date: string) {
      this.isFetchingPattern = true;
      this.editingUser = user;
      this.editingDate = date;

      const dateInt = dayjs(date).date();

      await monthlyScheduleModule.getUserShiftPattern({
        userId: user.userId,
        day: dateInt,
      });
      this.isFetchingPattern = false;
    },
    openAddScheduleModal(user: NurseryUserResponse, date: string) {
      this.addingUser = user;
      this.addingDate = date;
    },
    removeTimetableFromEditingPattern(index: number, timetableId?: number) {
      if (monthlyScheduleModule.editingShiftPattern) {
        const newPattern = { ...monthlyScheduleModule.editingShiftPattern };
        if (timetableId) {
          newPattern.timetables = newPattern.timetables?.map((tt) => {
            if (tt.timetableId === timetableId) {
              tt.operationType = "remove";
            }
            return tt;
          });
        } else {
          newPattern.timetables = newPattern.timetables.splice(index, 1);
        }
        monthlyScheduleModule.updateEditingPattern(newPattern);
      }
    },
    addTimetableToEditingPattern() {
      if (monthlyScheduleModule.editingShiftPattern) {
        const newPattern = { ...monthlyScheduleModule.editingShiftPattern };
        const newTimetable: DayScheduleUpdateRequestTimetable = {
          operationType: "add",
          name: "",
          abbreviation: "",
          color: "#029ec0",
          startMin: 0,
          endMin: 0,
          comment: "",
          category: "workDescription",
        };

        if (newPattern.timetables) {
          newPattern.timetables.push(newTimetable);
        } else {
          newPattern.timetables = [newTimetable];
        }
        monthlyScheduleModule.updateEditingPattern(newPattern);
      }
    },
    async saveEditingPattern() {
      if (this.editingUser && this.editingDate) {
        const dateInt = dayjs(this.editingDate).date();
        this.isUpdatingShift = true;
        await monthlyScheduleModule.saveEditingPattern({
          userId: this.editingUser.userId,
          date: dateInt,
        });
        monthlyScheduleModule.listMonthlySchedule();
        this.isUpdatingShift = false;

        monthlyScheduleModule.updateEditingPattern(null);
        this.editingUser = null;
        this.editingDate = null;
      }
    },
    async deleteEditingPattern() {
      if (this.editingUser && this.editingDate) {
        const dateInt = dayjs(this.editingDate).date();
        this.isUpdatingShift = true;
        await monthlyScheduleModule.deleteShiftPatternByDate({
          userId: this.editingUser.userId,
          date: dateInt,
        });
        monthlyScheduleModule.listMonthlySchedule();
        this.isUpdatingShift = false;

        monthlyScheduleModule.updateEditingPattern(null);
        this.editingUser = null;
        this.editingDate = null;
      }
    },
    async addPatternWithUserAndDate() {
      if (this.addingUser && this.addingDate && this.addingPatternId) {
        this.isApplying = true;
        const dateInt = dayjs(this.addingDate).date();
        await monthlyScheduleModule.applyPattern({
          shiftPatternId: this.addingPatternId,
          userId: this.addingUser.userId,
          days: [dateInt],
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));
        monthlyScheduleModule.listMonthlySchedule();
        this.isApplying = false;
        this.addingUser = null;
        this.addingDate = null;
        this.addingPatternId = null;
      }
    },
    async copyMembersFromLastMonth() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.isCopyingMembersFromLastMonth = true;

        const yearInt = dayjs(this.yearMonth).year();
        const monthInt = dayjs(this.yearMonth).month() - 1;

        const response = await ApiShift.listMonthlySchedule({
          nurseryId,
          year: yearInt,
          month: monthInt,
        });

        if (response) {
          const lastMonthMemberIds = response.items.map(
            (item) => item.nurseryUser.userId
          );
          const notRegistereds = lastMonthMemberIds.filter((userId) => {
            const findFromCurrent = this.monthlySchedules.find(
              (schedule) => schedule.nurseryUser.userId === userId
            );
            if (findFromCurrent) {
              return false;
            }
            return true;
          });

          notRegistereds.forEach((userId) => {
            this.addRegisterUserId(true, userId);
          });
        }

        this.isCopyingMembersFromLastMonth = false;
      }
    },
    getRegisteredSchedule(date: number) {
      if (this.applyingUser) {
        const findSchedules = this.monthlySchedules.find(
          (ms) => ms.nurseryUser.userId === this.applyingUser!.userId
        );
        if (findSchedules) {
          const targetDate = dayjs(`${this.yearMonth}-${date}`).format(
            "YYYY-MM-DD"
          );
          const dateSchedule = findSchedules.dailyPatterns.find(
            (dp) => dp.date === targetDate
          );
          if (dateSchedule) {
          // color をローカル変数として操作
          const color = dateSchedule.color?.split('&');
          if (color) {
            return {
              ...dateSchedule,
              color: color[0],
              textcolor: color[1] // color プロパティを更新せず、新しいオブジェクトを返す
            };
          }
          return dateSchedule;
          }
        }
      }

      return null;
    },
    // 相対輝度を計算する関数
    calculateRelativeLuminance(hexColor: string): number {
      const r = parseInt(hexColor.slice(1, 3), 16) / 255;
      const g = parseInt(hexColor.slice(3, 5), 16) / 255;
      const b = parseInt(hexColor.slice(5, 7), 16) / 255;

      const luminance = (channel: number) => {
        return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
      };

      return 0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);
    },
    calculateContrastRatio(color1: string, color2: string): number {
      const luminance1 = this.calculateRelativeLuminance(color1);
      const luminance2 = this.calculateRelativeLuminance(color2);
      const lighter = Math.max(luminance1, luminance2);
      const darker = Math.min(luminance1, luminance2);
      return (lighter + 0.05) / (darker + 0.05);
    },
    getStyle(dailyPattern) {
    let backgroundColor = dailyPattern.color;
    let fontColor = "white"; // デフォルトの文字色

    if (backgroundColor && backgroundColor.includes('&')) {
      const colors = backgroundColor.split('&');
      backgroundColor = colors[0]; // 背景色
      fontColor = colors[1]; // 文字色
    }

    // 動的なスタイルを生成
    return `background-color: ${backgroundColor}; color: ${fontColor};`;
  },
    async downloadShiftPattern() {
      this.isFetching = true;
      const response = await monthlyScheduleModule.dlShiftPattern();
      if (response) {
        alert(
          "エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。"
        );
      }
      this.isFetching = false;
    },
    openUpdatePlacement() {
      this.isUpdatePlacement = true;
    },
    async UpdateExpectedPlacement() {
      this.isFetching = true;
      this.isUpdatePlacement = false;
      await new Promise((resolve) => setTimeout(resolve, 20000));
      //console.log(`日付: ${this.yearMonth}`);
      await dailyScheduleModule.UpdateMonthlyExpectedPlacement(this.yearMonth + '-01');
      await new Promise((resolve) => setTimeout(resolve, 100000));
      console.log("計算完了");
      await Promise.all([
        monthlyScheduleModule.listMonthlySchedule(),
        monthlyScheduleModule.listShiftUsers(),
        shiftPatternModule.listShiftPatterns(),
        workDescriptionModule.listWorkDescriptions(),
      ]);
      await Promise.all([
        this.updateTableDates(),
      ]);
      this.isFetching = false;
    },
    async updateTableDates() {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth();
      const startOfMonth = dayjs(this.yearMonth).startOf("month");
      this.tableDates = [...Array(daysInMonth)].map((_, i) => {
        const date = dayjs(startOfMonth)
          .add(i, "day")
          .locale("ja")
          .format("M/D (ddd)");
        const dateToFindSummary = dayjs(startOfMonth)
          .add(i, "day")
          .format("YYYY-MM-DD");

        const summary = this.getSummaries()?.find(
          (s) => s.date === dateToFindSummary
        ) as {
          date: string;
          overall: {
            maxGovExpected: number | null;
            minGovExpected: number | null;
          };
        };

        return {
          date,
          summaryMax:
            summary && summary.overall.maxGovExpected
              ? String(summary.overall.maxGovExpected)
              : "-",
          summaryMin:
            summary && summary.overall.minGovExpected
              ? String(summary.overall.minGovExpected)
              : "-",
        };
      });
    },
    getSummaries() {
      if (monthlyScheduleModule.monthlySchedule) {
        return monthlyScheduleModule.monthlySchedule.summaries;
      }
      return undefined;
    },
  },
  async mounted() {
    this.isFetching = true;
    await Promise.all([
      monthlyScheduleModule.listMonthlySchedule(),
      monthlyScheduleModule.listShiftUsers(),
      shiftPatternModule.listShiftPatterns(),
      workDescriptionModule.listWorkDescriptions(),
    ]);
    await Promise.all([
      this.updateTableDates(),
    ]);
    this.isFetching = false;
  },
});
