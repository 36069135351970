import ApiClient from '@/api/ApiClient';
import { CreateDefecationRequest, CreateMealRequest, CreateMilkRequest, CreateTemperatureRequest, UpdateDefecationRequest, UpdateMealRequest, UpdateMilkRequest, UpdateTemperatureRequest } from '@/model/Request';
import { DefecationResponse, DiaryResponse, MealResponse, MilkResponse, TemperatureResponse } from 'chaild-api/lib';

export default class ApiChildReport {
  // 園児の日誌
  public static async searchChildDiary(input: {
    classId?: number;
    date?: string;
    limit?: number;
    skip?: number;
    nurseryId?: number;
  }) {
    const response = await ApiClient.get('/children/diary/search', input)
    if (response && response.diaries) {
      return response;
    }
    return null;
  }

  public static async findChildDiary(childId: number, date: string): Promise<DiaryResponse | null> {
    const response = await ApiClient.get(`/children/${childId}/diary?date=${date}`, null);
    if (response) {
      return response as DiaryResponse;
    }
    return null;
  }

  // 個人日誌の新規作成
  public static async createChildDiary(input: { childId: number; date: string; content: string }): Promise<DiaryResponse> {
    const response = await ApiClient.post(`/children/${input.childId}/diary`, input);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 排便
  // ＝＝＝＝＝＝＝＝

  public static async saveDefecation(input: CreateDefecationRequest): Promise<DefecationResponse> {
    const response = await ApiClient.post(`/children/${input.childId}/defecation`, input);
    return response;
  }

  public static async updateDefecation(input: UpdateDefecationRequest): Promise<DefecationResponse> {
    const response = await ApiClient.put(`/children/${input.childId}/defecation/${input.defecationId}`, input);
    return response;
  }

  public static async deleteDefecation(input: { childId: number; defecationId: number }) {
    const response = await ApiClient.delete(`/children/${input.childId}/defecation/${input.defecationId}`, null);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 体温
  // ＝＝＝＝＝＝＝＝

  public static async saveTemperature(input: CreateTemperatureRequest): Promise<TemperatureResponse> {
    const response = await ApiClient.post(`/children/${input.childId}/temperature`, input);
    return response;
  }

  public static async updateTemperature(input: UpdateTemperatureRequest): Promise<TemperatureResponse> {
    const response = await ApiClient.put(`/children/${input.childId}/temperature/${input.temperatureId}`, input);
    return response;
  }

  public static async deleteTemperature(input: { childId: number; temperatureId: number }) {
    const response = await ApiClient.delete(`/children/${input.childId}/temperature/${input.temperatureId}`, null);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // ミルク
  // ＝＝＝＝＝＝＝＝

  public static async saveMilk(input: CreateMilkRequest): Promise<MilkResponse> {
    const response = await ApiClient.post(`/children/${input.childId}/milk`, input);
    return response;
  }

  public static async updateMilk(input: UpdateMilkRequest): Promise<MilkResponse> {
    const response = await ApiClient.put(`/children/${input.childId}/milk/${input.milkId}`, input);
    return response;
  }

  public static async deleteMilk(input: { childId: number; milkId: number }) {
    const response = await ApiClient.delete(`/children/${input.childId}/milk/${input.milkId}`, null);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 喫食
  // ＝＝＝＝＝＝＝＝

  public static async createMeal(input: CreateMealRequest): Promise<MealResponse> {
    const response = await ApiClient.post(`/children/${input.childId}/meal`, input);
    return response;
  }

  public static async updateMeal(input: UpdateMealRequest): Promise<MealResponse> {
    const response = await ApiClient.put(`/children/${input.childId}/meal/${input.mealId}`, input);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 活動記録/発達状況
  // ＝＝＝＝＝＝＝＝

  public static async updateContent(input: {
    childId: number;
    diaryId: number;
    content: string;
  }): Promise<DiaryResponse> {
    const response = await ApiClient.put(`/children/${input.childId}/diary/${input.diaryId}`, input);
    return response;
  }
}